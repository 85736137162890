
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      garuda: {
        title: "The Garuda Studio",
        subtitle: "Specialized Physical Training and Therapy",
        subColor: "var(--garuda)",
        map: "Garuda.png",
        paragraph: {
          p1: "The Garuda Fitness Studio is our high-end fitness center with a patented exercise apparatus and accredited Garuda Method training program. Developed by Glynn Barber and James D'Silva, the Garuda is a low-impact exercise machine using mind and body to strengthen core and balance.",
          p2: "The Garuda center is used for specialized physical training &amp; physical therapy programs on an individual basis, hosting bootcamps, and education for our clients.",
        },
        logo: "garuda-logo.svg",
        images: {
          img1: "img1_small.jpg",
          img2: "img2_small.jpg",
          img3: "img3_small.jpg",
          img4: "img4_small.jpg",
        },
        link: "garuda",
      },
    };
  },
});
