
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      greenhouse: {
        title: "The Greenhouse",
        subtitle: "The Heart of FARMacy",
        subColor: "var(--greenhouse)",
        map: "Greenhouse.png",
        paragraph: {
          p1: "The greenhouse holds an ECSIA<sup>&reg;</sup> Farmer's Commercial Module (FCM). The greenhouse supplies the vegetables, fruits, and herbs needed for processing nutraceutical food blends, the Blue Crayz restaurant and juice bar, fresh baby foods, and more.",
        },
        images: {
          img1: "img6_small.jpg",
          img2: "img10_small.jpg",
          img3: "img9_small.jpg",
          img4: "img5_small.jpg",
        },
        link: "greenhouse",
      },
    };
  },
});
