
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      pickup: {
        title: "FARMacy Pick Up",
        subtitle: "Nutraceutical Food Blends",
        subColor: "var(--pickup);",
        map: "FARMacyPickup.png",
        paragraph: {
          p1: "The FARMacy pick-up center fulfils everyday pickups for our specialized nutraceutical blends. Pickups are facilitated through the FARMacy drive-through window and FARMacy retail area.",
        },
        images: {
          img1: "img1_small.jpg",
          img2: "img2_small.jpg",
          img3: "img3_small.jpg",
          img4: "img4_small.jpg",
        },
        link: "pickup",
      },
    };
  },
});
